<template>
  <div v-if="visible">
    <el-dialog :visible="true" title="分配系统" width="800px" :before-close="closeTab" :close-on-click-modal="false">
      <el-row :gutter="20" style="height: 400px">
        <el-col :span="4">
          <p style="margin: 0">给用户分配系统</p>
        </el-col>
        <el-col :span="20">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkedAll" @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checked" @change="handleChoose">
            <el-row>
              <el-col v-for="item in systems" :key="item.usId" :span="8" style="margin-bottom:10px">
                <el-checkbox :label="item.usId">{{item.usName}}</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { updateUserSystem } from '@/api/account'
import { updateRoleSystem } from '@/api/systemRole'
export default {
  data() {
    return {
      systems: [],
      checked: [],
      checkedAll: false,
      isIndeterminate: true,
      otherIdList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    systemList: {
      type: Array,
      default: function() {
        return []
      }
    },
    ids: {
      type: Array,
      default: function() {
        return []
      }
    },
    Id: {
      type: Number,
      default: null
    },
    // 给用户分配 user 角色role
    type: {
      type: String,
      default: 'user'
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.systems = this.systemList
        this.checked = this.ids

        const systemIdList = this.systemList.map(item => item.usId)
        this.otherIdList = this.ids.filter(item => !systemIdList.includes(item))

        this.checkedAll = this.ids.length - this.otherIdList.length === this.systemList.length
        this.isIndeterminate = this.ids.length > 0 && this.ids.length - this.otherIdList.length < this.systemList.length
      }
    }
  },
  methods: {
    closeTab() {
      this.otherIdList = []
      this.$emit('update:visible', false)
    },
    handleCheckAllChange(val) {
      this.checked = val ? this.otherIdList.concat(this.systemList.map(item => item.usId)) : this.otherIdList
      this.checkedAll = Boolean(val)
      this.isIndeterminate = false
    },
    handleChoose(val) {
      this.checkedAll = val.length === this.systemList.length
      this.isIndeterminate = val.length > 0 && val.length < this.systemList.length
    },
    submit() {
      if (this.type === 'role') {
        updateRoleSystem({ roleId: this.Id, usIds: this.checked }).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.closeTab()
            this.$emit('success')
          }
        }).catch()
      } else {
        updateUserSystem({ userId: this.Id, usIds: this.checked }).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.closeTab()
            this.$emit('success')
          }
        }).catch()
      }
    }
  }
}
</script>
