<template>
  <div class="app-container">
    <el-form ref="searchForm" :model="searchForm" inline size="small">
      <el-form-item label="公司" prop="ucId">
        <el-cascader
          v-model="searchForm.ucId"
          :options="companyData"
          :props="companyProps"
          change-on-select
          @change="getChildCompany"
          clearable
          :key="num"
          :show-all-levels="false">
        </el-cascader>
      </el-form-item>
      <el-form-item label="部门" prop="udId">
        <el-cascader
          v-model="searchForm.udId"
          :options="departData"
          :props="departProps"
          change-on-select
          @change="getChildDepart"
          :show-all-levels="false"
          clearable
          :key="num"
          :disabled="searchForm.ucId.length===0"
          placeholder="请先选择公司">
        </el-cascader>
      </el-form-item>
      <el-form-item label="职位" prop="upId">
        <el-cascader
          v-model="searchForm.upId"
          :options="positionData"
          :props="positionProps"
          change-on-select
          @change="getPosition"
          :show-all-levels="false"
          clearable
          :disabled="searchForm.udId.length===0"
          placeholder="请先选择部门">
        </el-cascader>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="searchForm.name" clearable placeholder="要查询的名称"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="username">
        <el-input v-model="searchForm.username" clearable placeholder="登录账号"></el-input>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-select v-model="searchForm.state" clearable placeholder="账号状态">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="2"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="用户类型" prop="userType">
        <el-select v-model="searchForm.userType" placeholder="账号类型">
          <el-option label="员工" :value="0"></el-option>
          <el-option label="非员工" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button size="small" @click="clear">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="dataList" size="small" border height="calc(100vh - 270px)" :header-cell-style="{background: '#41A1FF', color:'#fff'}">
      <el-table-column label="登录名" prop="username" align="center"></el-table-column>
      <el-table-column label="姓名" prop="name" align="center"></el-table-column>
      <el-table-column label="部门" prop="udName" align="center"></el-table-column>
      <el-table-column label="职位" prop="upName" align="center"></el-table-column>
      <el-table-column label="在职状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status===1">在职</span>
          <span v-else-if="scope.row.status===0">离职</span>
        </template>
      </el-table-column>
      <el-table-column label="用户类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.userType===0">员工</span>
          <span v-else-if="scope.row.userType===1">非员工</span>
        </template>
      </el-table-column>
      <el-table-column label="系统和权限" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" placement="top" content="为系统分配权限">
            <svg-icon icon-class="config" @click="getSystem('power', scope.row)" style="width: 16px; height: 16px; cursor:pointer;"></svg-icon>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="分配系统" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" placement="top" content="分配系统">
            <svg-icon icon-class="allot" @click="getSystem('system', scope.row)" style="width: 16px; height: 16px; cursor:pointer;"></svg-icon>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:1em; text-align:center">
      <el-pagination
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page="page"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange">
      </el-pagination>
    </div>
    <!-- 分配系统 -->
    <system-conf v-bind.sync="sysConf"></system-conf>
    <!-- 分配权限 -->
    <power-conf v-bind.sync="powerConf"></power-conf>
  </div>
</template>
<script>
import { deepClone, cleanParams } from '@/utils/index'
import { userCompany, getuserDepartment, getuserPosition, getAllAccount, userSystem, getAuthorizationSystem } from '@/api/account'
import { userSystemMap } from '@/api/system-list'
import systemConf from './systemConf'
import powerConf from '@/components/powerConf'
export default {
  components: { systemConf, powerConf },
  data() {
    return {
      searchForm: {
        ucId: [],
        udId: [],
        upId: [],
        name: '',
        username: '',
        state: null,
        userType: null
      },
      num: 1, // 级联数据清空，需要重新加载组件
      companyData: [],
      departData: [],
      positionData: [],
      companyProps: {
        label: 'ucName',
        value: 'ucId',
        children: 'childrenCompanyList'
      },
      departProps: {
        label: 'udName',
        value: 'udId',
        children: 'childrenDepartmentList'
      },
      positionProps: {
        label: 'upName',
        value: 'upId',
        children: 'childrenPositionList'
      },
      dataList: [],
      pageSize: 10,
      page: 1,
      total: 0,
      systemList: [], // 登录账户被赋予的系统
      sysConf: {
        visible: false,
        systemList: [],
        ids: [],
        Id: null
      },
      powerConf: {
        visible: false,
        systemList: [],
        roleId: null,
        userId: null
      },
      loading: false
    }
  },
  created() {
    this.getCompany()
    this.getData()
    this.getPowers()
  },
  methods: {
    getCompany() {
      userCompany().then(res => {
        if (res.code === '000000' && res.data) {
          this.companyData = res.data
          this.companyData.forEach(item => {
            if (item.hasMore) {
              item.childrenCompanyList = []
            }
          })
        }
      }).catch(() => {
        this.companyData = []
      })
    },
    // 公司级联，每次用公司ID去请求子公司列表
    getChildCompany(val) {
      this.searchForm.upId = []
      if (val.length === 0) {
        ++this.num
        this.companyData = []
        this.getChildDepart()
        return
      }
      const ID = val[val.length - 1]
      const params = {
        ucParentId: ID
      }
      userCompany(params).then(res => {
        if (res.code === '000000' && res.data) {
          res.data.forEach(item => {
            if (item.hasMore) {
              item.childrenCompanyList = []
            }
          })
          const parent = deepClone(this.companyData)
          this.findParent(ID, parent, res.data, 'childrenCompanyList', 'ucId')
          setTimeout(() => {
            this.companyData = parent
          }, 100)
        }
      }).catch(() => {
        this.companyData = []
      })
      // 获取部门(部门级联数据，改变是请求子部门)
      getuserDepartment(ID).then(res => {
        if (res.code === '000000' && res.data) {
          res.data.forEach(item => {
            if (item.hasMore) {
              item.childrenDepartmentList = []
            }
          })
          this.departData = res.data
        }
      }).catch(() => {
        this.departData = []
      })
      this.$forceUpdate()
    },
    // 部门级联，每次用部门ID去请求子部门ID列表
    getChildDepart(val) {
      if ((val && val.length && val.length === 0) || val === undefined) {
        this.searchForm.udId = []
        this.departData = []
        this.positionData = []
        this.searchForm.upId = []
        return
      }
      const ID = this.searchForm.udId[this.searchForm.udId.length - 1]
      const ucID = this.searchForm.ucId[this.searchForm.ucId.length - 1]
      getuserDepartment(ucID, ID).then(res => {
        if (res.code === '000000' && res.data) {
          res.data.forEach(item => {
            if (item.hasMore) {
              item.childrenDepartmentList = []
            }
          })
          const parent = deepClone(this.departData)
          this.findParent(ID, parent, res.data, 'childrenDepartmentList', 'udId')
          setTimeout(() => {
            this.departData = parent
          }, 100)
        }
      }).catch(() => {
        this.departData = []
      })
      // 获取职位列表
      getuserPosition(ID).then(res => {
        if (res.code === '000000') {
          res.data.forEach(item => {
            if (item.hasMore) {
              item.childrenPositionList = []
            }
          })
          this.positionData = res.data
        }
      }).catch(() => {
        this.positionData = []
      })
      this.$forceUpdate()
    },
    // 职位级联，每次用职位ID去请求下级职位列表
    getPosition() {
      const ID = this.searchForm.upId[this.searchForm.upId.length - 1]
      const udID = this.searchForm.udId[this.searchForm.udId.length - 1]
      getuserPosition(udID, ID).then(res => {
        if (res.code === '000000') {
          res.data.forEach(item => {
            if (item.hasMore) {
              item.childrenPositionList = []
            }
          })
          const parent = deepClone(this.positionData)
          this.findParent(ID, parent, res.data, 'childrenPositionList', 'upId')
          setTimeout(() => {
            this.positionData = parent
          }, 100)
        }
      }).catch(() => {
        this.positionData = []
      })
      this.$forceUpdate()
    },
    // 递归查找子公司列表该插到那个id下(部门，职位公用)
    findParent(id, arr, child, type, idType) {
      arr.forEach(item => {
        if (id === item[idType]) {
          if (child.length > 0) {
            item[type] = child
          }
        } else {
          if (item[type]) {
            this.findParent(id, item[type], child, type, idType)
          }
        }
      })
    },
    clear() {
      this.$refs.searchForm.resetFields()
    },
    search() {
      if (this.page === 1) {
        this.getData()
      } else {
        this.handleCurrentChange(1)
      }
    },
    getData() {
      console.log(this.searchForm)
      let params = {
        ucId: this.searchForm.ucId.length > 0 ? this.searchForm.ucId[this.searchForm.ucId.length - 1] : null,
        udId: this.searchForm.udId.length > 0 ? this.searchForm.udId[this.searchForm.udId.length - 1] : null,
        upId: this.searchForm.upId.length > 0 ? this.searchForm.upId[this.searchForm.upId.length - 1] : null,
        name: this.searchForm.name,
        username: this.searchForm.username,
        roleId: 3, // 分配权限是针对普通用户
        status: this.searchForm.status,
        userType: this.searchForm.userType,
        pageSize: this.pageSize,
        pageNum: this.page,
        flag: true
      }
      params = cleanParams(params)
      this.loading = true
      getAllAccount(params).then(res => {
        if (res.code === '000000') {
          this.dataList = res.data.list
          this.total = res.data.total
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getData()
    },
    // 查询当前用户已有的系统，（分配系统，跟当前登录账户的系统取交集作为已选中）
    getSystem(type, item) {
      this.loading = true
      userSystem(item.userId).then(res => {
        if (res.code === '000000') {
          if (type === 'system') {
            this.sysConf = {
              visible: true,
              systemList: this.systemList,
              ids: res.data.usIds || [],
              Id: item.userId,
              type: 'user'
            }
          } else if (type === 'power') {
            // 登录用户赋给当前用户的系统
            const system = []
            if (res.data.usIds) {
              this.systemList.forEach(item => {
                if (res.data.usIds.includes(item.usId)) {
                  system.push(item)
                }
              })
              this.powerConf = {
                visible: true,
                systemList: system,
                roleId: null,
                userId: item.userId
              }
            } else {
              this.$message.warning('请先给该账号分配系统')
            }
          }
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      }).catch(() => { this.loading = false })
    },
    // 登录用户被指派的系统(超管则是全部系统)
    getPowers() {
      this.systemList = []
      if (this.$store.getters.roles.userInfo.roleId === 1) {
        userSystemMap().then(res => {
          if (res.code === '000000') {
            this.systemList = res.data
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        getAuthorizationSystem(this.$store.getters.roles.userInfo.userId).then(res => {
          if (res.code === '000000') {
            this.systemList = res.data
          }
        }).catch()
      }
    }
  }
}
</script>

